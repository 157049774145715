<template>
    <div class="seongdong_curation_wrap country" :class="getCurationCss">
        <ul class="setting_target_detail">
            <h2 class="curation_body_list_title"> 국내곡 비율 </h2>
            <div class="percent_bar">
                <div class="percent_item half first" :class="{ checked: parseInt(domestic) > 0 }"
                     @click.prevent="clickCountry('0')">
                    <input type="checkbox" id="zero" hidden value="0">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 10 }" @click.prevent="clickCountry('10')">
                    <div v-show="domestic == '10'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 20 }" @click.prevent="clickCountry('20')">
                    <div v-show="domestic == '20'" class="last_checked"></div>
                    <label for="twenty" class="percent-dot"></label>
                    <input type="checkbox" id="twenty" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 30 }" @click.prevent="clickCountry('30')">
                    <div v-show="domestic == '30'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 40 }" @click.prevent="clickCountry('40')">
                    <div v-show="domestic == '40'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 50 }" @click.prevent="clickCountry('50')">
                    <div v-show="domestic == '50'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 60 }" @click.prevent="clickCountry('60')">
                    <div v-show="domestic == '60'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 70 }" @click.prevent="clickCountry('70')">
                    <div v-show="domestic == '70'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 80 }" @click.prevent="clickCountry('80')">
                    <div v-show="domestic == '80'" class="last_checked"></div>
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item" :class="{ checked: parseInt(domestic) > 90 }">
                    <label for="ten" class="percent-dot"></label>
                    <input type="checkbox" id="ten" hidden value="10">
                </div>
                <div class="percent_item half last">
                    <input type="checkbox" id="onehundred" hidden value="100">
                </div>
            </div>
            <div class="curation_percent">
                <span class="percent_text">국내 {{ domesticRate }}%</span>
                <span class="percent_text">해외 {{ 100 - Number(domesticRate) }}%</span>
            </div>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SeongDongCurationCountry',
  emits: ['send-curation'],
  data () {
    return {
      domestic: '0',
      alertCountry: false
    };
  },
  props: {
    domesticRate: String
  },
  created () {
    this.domestic = this.domesticRate;
  },
  mounted () {

  },

  methods: {
    clickCountry (val) {
      if (parseInt(val) > 80) {
        this.alertCountry = true;
        return false;
      }
      this.alertCountry = false;
      if (this.domestic !== val) {
        this.domestic = val;
        this.$emit('send-curation', {
          type: "domesticRate",
          value: val
        });
        // this.$emit('send-curation', value);
      }
    }
  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
